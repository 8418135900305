<template>
  <div class="car-following">
    <div style="height:100%;background: #FFFFFF;border-radius: 10px 0px 0px 10px;">
        <el-input class="car-following-left-input"
          placeholder="搜索"
          prefix-icon="el-icon-search"
          v-model="input">
        </el-input>
      <div class="car-following-left">
        <div class="car-following-left-item" :class="{ 'car-following-left-current': currentVehicle.vehicleId == item.vehicleId }" v-for="item,index in carList" :key="index" @click="handleCheckCar(item)">
          <!-- <img :src="item.imageUrl" class="car-following-left-item-icon" alt="" srcset=""> -->
          
      <Iconfont
        class="car-following-left-item-icon"
        :size="42"
        :name="`https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/${
          carImgList.includes(
            item.machineryProductType +
              item.machineryEquipmentType
          )
            ? item.machineryProductType +
              item.machineryEquipmentType
            : carImgList.includes(item.machineryProductType)
            ? item.machineryProductType
            : 'excavating_machinery'
        }.png`"
      ></Iconfont>
          <div class="car-following-left-item-center">
            <div class="car-following-left-item-center-top">{{ item.plate || '--' }}</div>
            <div class="car-following-left-item-center-dowm">跟车次数：{{ item.carSumCount || '0' }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="car-following-right">
      <div class="car-following-right-s">
        <div class="car-following-right-s-item" v-for="item,index in statisticsList" :key="index" :class="[item.className]">
          <div class="car-following-right-s-item-left">
            <div class="car-following-right-s-item-left-top"> {{ item.label }} </div> 
            <div class="car-following-right-s-item-left-dowm"> {{ item.type == 'boolean' ? (currentVehicle[item.key] ? item.inactive : item.active) : currentVehicle[item.key] || '--' }} </div>
          </div>
          <img class="car-following-right-s-item-icon" :src="item.icon" alt="" srcset="">
        </div>
      </div>
      <FollowCarRecord style="height: calc(100% - 90px)" isAdd :isConditions="false" v-model="currentVehicle" />
    </div>
  </div>
</template>

<script>
import FollowCarRecord from "@/views/RealTimePositioning/components/FollowCarRecord";
import { followCount } from "@/api/riskData";
export default {
  components:  { FollowCarRecord },
  props: {
    paramsData: {
      type: Object,
      default: () => {},
    },
    projectId: {
      type: [Number, String],
      default: null,
    },
    customerUserId: {
      type: [Number, String],
      default: null,
    },
    conditionObj: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    projectId: {
      handler(v) {
        this.init();
      }
    },
    // customerUserId: {
    //   handler(v) {
    //     clearTimeout(this.timeObj);
    //     this.timeObj = setTimeout(() => {
    //       if (!v) {
    //         return false;
    //       }
    //       this.getList();
    //       clearTimeout(this.timeObj);
    //     },100);
    //   },
    // },
    // currentVehicle: {
    //   handler(v) {
    //     this.getList();
    //   },
    // },
  },
  data() {
    return {
      input: '',
      statisticsList: [
        {
          label: '是否需要跟车',
          key: 'isCar',
          icon: require('../../../assets/images/newRiskControl/statistics-kg-icon.png'),
          className: 'kg'
        },
        {
          label: '昨天跟车情况',
          key: 'isCarYesterday',
          icon: require('../../../assets/images/newRiskControl/statistics-gc-icon.png'),
          className: 'gc',
          type: 'boolean',
          active: '未跟车',
          inactive: '已跟车'
        },
        {
          label: '总跟车次数',
          key: 'carSumCount',
          icon: require('../../../assets/images/newRiskControl/statistics-cs-icon.png'),
          className: 'cs'
        },
      ],
      timeObj: null,
      carList: [],
      currentVehicle: {},
      
      carImgList: [
        "aerial_work_machinery1",
        "aerial_work_machinery2",
        "compaction_machinery",
        "excavating_machinery",
        "excavating_machinery1",
        "hoisting_machinery",
        "hoisting_machinery3",
        "mining_machinery",
        "piling_machinery1",
        "shovel_transport_machinery0",
        "shovel_transport_machinery1",
        "shovel_transport_machinery3",
        "industrial_vehicles1",
        "agriculture_mechanics1",
        "concrete_machinery3",
        "shovel_transport_machinery9",
        "diao_guan_ji0",
        "yi_dong_dian_zhan0",
        "han_jie_gong_zuo_zhan0",
        "zhong_pin_jia_re_che0",
          'new_energy_vehicles0',
      ],
    }
  },
  methods: {
    async init() {
      let data = await followCount({
        projectId: this.projectId,
      });
      if (data.code == 200) {
        this.carList = data.data || [];
        if (this.carList.length > 0) {
          this.currentVehicle = this.carList[0];
        }
      }else {
        this.carList = []
      }
    },
    async getList() {
      // let data = await followPage();
    },
    handleCheckCar(item) {
      this.currentVehicle = item;
    }
  }
}
</script>

<style lang="scss" scoped>
.car-following {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  &-left {
    position: relative;
    width: 250px;
    height: calc(100% - 52px);
    padding: 10px 0 10px;
    overflow-y: auto;
    &-input {
      width: calc(100% - 20px);
      margin: 10px;
    }
    &-item {
      display: flex;
      padding: 10px;
      border-top: 1px solid #EFEFEF;
      cursor: pointer;
      &-icon {
        width: 42px;
        height: 42px;
        margin-right: 8px;
        ::v-deep img {
          margin: 0 !important;
        }
      }
      &-center {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &-top {
          font-weight: bold;
          font-size: 14px;
          color: #333333;
        }
        &-dowm {
          font-size: 14px;
          color: #333333;
        }
      }
    }
    &-current {
      background: #F2F7FD;
      .car-following-left-item-center {
        .car-following-left-item-center-top {
          color: #0080FF;
        }
      }
    }
  }
  &-right {
    width: calc(100% - 250px);
    padding: 16px 18px;
    border-left: 1px solid #EFEFEF;
    background-color: #fff;
    &-s {
      display: flex;
      gap: 16px;
      &-item {
        flex: 1;
        height: 98px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 24px 16px 32px;
        border-radius: 6px;
        &-left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          &-top {
            font-weight: 400;
            font-size: 16px;
            color: #666666;
          }
          &-dowm {
            font-weight: bold;
            font-size: 24px;
          }
        }
        &-right {
          width: 42px;
          height: 42px;
        }
      }
      .kg {
        background: #F2F9FF;
        border: 1px solid #A2D0FF;
        .car-following-right-s-item-left-dowm {
          color: #3A8EE6 !important;
        }
      }
      .gc {
        background: #FFFBF2;
        border: 1px solid #FFDA8F;
        .car-following-right-s-item-left-dowm {
          color: #F2A200 !important;
        }
      }
      .cs {
        background: #FFF6F2;
        border: 1px solid #FFBEA2;
        .car-following-right-s-item-left-dowm {
          color: #E55C17 !important;
        }
      }
    }
  }
}
</style>