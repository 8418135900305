var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"car-following"},[_c('div',{staticStyle:{"height":"100%","background":"#FFFFFF","border-radius":"10px 0px 0px 10px"}},[_c('el-input',{staticClass:"car-following-left-input",attrs:{"placeholder":"搜索","prefix-icon":"el-icon-search"},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}}),_c('div',{staticClass:"car-following-left"},_vm._l((_vm.carList),function(item,index){return _c('div',{key:index,staticClass:"car-following-left-item",class:{ 'car-following-left-current': _vm.currentVehicle.vehicleId == item.vehicleId },on:{"click":function($event){return _vm.handleCheckCar(item)}}},[_c('Iconfont',{staticClass:"car-following-left-item-icon",attrs:{"size":42,"name":`https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/${
        _vm.carImgList.includes(
          item.machineryProductType +
            item.machineryEquipmentType
        )
          ? item.machineryProductType +
            item.machineryEquipmentType
          : _vm.carImgList.includes(item.machineryProductType)
          ? item.machineryProductType
          : 'excavating_machinery'
      }.png`}}),_c('div',{staticClass:"car-following-left-item-center"},[_c('div',{staticClass:"car-following-left-item-center-top"},[_vm._v(_vm._s(item.plate || '--'))]),_c('div',{staticClass:"car-following-left-item-center-dowm"},[_vm._v("跟车次数："+_vm._s(item.carSumCount || '0'))])])],1)}),0)],1),_c('div',{staticClass:"car-following-right"},[_c('div',{staticClass:"car-following-right-s"},_vm._l((_vm.statisticsList),function(item,index){return _c('div',{key:index,staticClass:"car-following-right-s-item",class:[item.className]},[_c('div',{staticClass:"car-following-right-s-item-left"},[_c('div',{staticClass:"car-following-right-s-item-left-top"},[_vm._v(" "+_vm._s(item.label)+" ")]),_c('div',{staticClass:"car-following-right-s-item-left-dowm"},[_vm._v(" "+_vm._s(item.type == 'boolean' ? (_vm.currentVehicle[item.key] ? item.inactive : item.active) : _vm.currentVehicle[item.key] || '--')+" ")])]),_c('img',{staticClass:"car-following-right-s-item-icon",attrs:{"src":item.icon,"alt":"","srcset":""}})])}),0),_c('FollowCarRecord',{staticStyle:{"height":"calc(100% - 90px)"},attrs:{"isAdd":"","isConditions":false},model:{value:(_vm.currentVehicle),callback:function ($$v) {_vm.currentVehicle=$$v},expression:"currentVehicle"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }