<template>
  <div style="height:100%">
    <RtpPlayer ref="RtpPlayer"
      :videoList="videoList" :isConditions="false"
      :speakVehicle="speakVehicle"
      :videoGrid="4"
      :videoCloseTime="realTimeConfig.videoCloseTime"
      @playStart="onPlayStart"
      @playEnd="onPlayEnd"
      @speakEnd="onSpeakEnd"
      :Z="vehicle.Z"
    ></RtpPlayer>

    <div class="videoplayend" v-if="visible">
      <label>提示</label>
      <div class="content">
        <img class="tipicon" src="@/assets/images/tip.png" />
        <span>您已长时间不操作系统，视频已经自动关闭</span>
      </div>
    </div>
  </div>
</template>

<script>
import RtpPlayer from "@/views/RealTimePositioning/RtpPlayer.vue";
import { getTreeNodeId } from "@/utils/treeHelper";
import { mapState } from "vuex";
export default {
  components: { RtpPlayer },
  data() {
    return {
      // 实时视频列表
      videoList: [],
      visible: false,
    };
  },
  computed: {
    ...mapState(["userConfig", "pathVehicle", "userInfo"]),
    realTimeConfig() {
      return this.userConfig.realTimeConfig;
    },
    speakVehicle() {
      const { currentSpeak } = this;
      if (!currentSpeak) return null;

      // 终端号
      const device = currentSpeak.N;
      // 设备协议类型 1: GF-1078, GB-1078; 2: GA系列
      // const protocolType = ["GF-1078", "GB-1078"].includes(currentSpeak.T)
      //   ? 1
      //   : 2;
      const protocolType = currentSpeak.T.indexOf("GA-") < 0
        ? 1
        : 2;
      // 特殊协议 0: 不处理; 1: 粤标
      const SpecialSign = currentSpeak.percentageOfFlow === 6 ? 1 : 0;
      // 设备名称
      const plate = currentSpeak.P;
      // 车辆Id
      const vehicleId = currentSpeak.V;
      // 车组Id
      const groupId = currentSpeak.M;

      return {
        device,
        protocolType,
        SpecialSign,
        plate,
        vehicleId,
        groupId,
      };
    },
  },
  props: {
    vehicle: {
      type: Object,
      default: () => {},
    },
    currentVehicle: {
      type: Object,
      default: () => {},
    },
  },
  model: {
    prop: "currentVehicle",
  },
  methods: {
    getList() {
      this.currentSpeak = this.vehicle;
      this.$nextTick(()=>{
        console.log(this.currentVehicle,'--------------------');
        this.handlePlayClick();
      })
    },
    handlePlayClick() {
      const data = this.currentVehicle;
      this.videoPlate = data.P;
      const videoList = [];
      const channels = this.parseChannels(data.camreaLine);
      const cameraCH = this.parseChannels(data.cameraCH);

      // 终端号
      const device = data.N;
      // 设备协议类型 1: GF-1078, GB-1078; 2: GA系列
      // const protocolType = ["GF-1078", "GB-1078"].includes(data.T) ? 1 : 2;
      const protocolType = data.T.indexOf("GA-") < 0
        ? 1
        : 2;
      // 特殊协议 0: 不处理; 1: 粤标
      const SpecialSign = data.percentageOfFlow === 6 ? 1 : 0;
      // 设备名称
      const plate = data.P;
      // 车辆Id
      const vehicleId = data.V;
      // 车组Id
      const groupId = data.M;

      channels.forEach((channel, ind) => {
        const key = getTreeNodeId(data.M, data.V, channel);
        if (videoList.some((obj) => obj.key === key)) return;
        videoList.push({
          key,
          channel,
          channelsName: cameraCH ? cameraCH[ind] : "",
          device,
          protocolType,
          SpecialSign,
          plate,
          vehicleId,
          groupId,
        });
      });
      this.videoList = videoList;
    },
    onPlayStart(channelObj) {
      const { videoList } = this;
      if (!videoList.some((obj) => obj.key === channelObj.key)) {
        videoList.push(channelObj);
      }
    },
    onPlayEnd(channelObj) {
      const { videoList } = this;
      this.videoList = videoList.filter((obj) => obj.key !== channelObj.key);

      if (!this.videoList.length && channelObj.countdownEnd) {
        this.visible = true;
        this.videoPlate = null;
        setTimeout(() => {
          this.visible = false;
        }, 4000);
      }
    },
    onSpeakEnd() {
      this.currentVehicle = null;
    },
    parseChannels(channelStr) {
      try {
        return JSON.parse(channelStr);
      } catch (err) {
        //
      }
      return [];
    },
    onCloseAll() {
      this.videoList = [];
      this.$refs.RtpPlayer.onCloseAll();
    }
  },
};
</script>

<style lang="scss" scoped>
.videoplayend {
  width: 405px;
  height: 110px;
  background: #fff;
  z-index: 99;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-20%, -70%);

  & > label {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 37px;
    background: #212d3e;
    color: #fff;
    padding: 0 10px;
    line-height: 37px;
  }
}
</style>